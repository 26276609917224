<template>
  <div
    data-testid="products-dropdown"
    class="products-mobile"
  >
    <span
      v-if="products.length === 1"
      class="products-mobile__name products-mobile__name_single"
    >
      {{ activeProduct.name }}
    </span>

    <span
      v-else
      class="products-mobile__name"
      :class="{'products-mobile__name_active': open}"
      @click="open = !open"
    >
      {{ activeProduct.name }}
    </span>

    <ul
      v-if="open"
      class="products-mobile__list"
    >
      <li
        v-for="product in products"
        :key="product.id"
        :class="{ 'products-mobile__item_active': product.id === activeProduct.id }"
        class="products-mobile__item"
        @click="selectProduct(product)"
      >
        <span class="products-mobile__item-name">
          {{ product.name }}
        </span>

        <div class="products-mobile__item-selection" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ProductsMobile',

  props: {
    products: {
      type: Array,
      required: true,
    },

    activeProduct: {
      type: Object,
      required: true,
    },

    setActiveProduct: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      open: false,
    };
  },

  methods: {
    closeProducts() {
      this.open = false;
    },

    selectProduct(product) {
      new Promise(resolve => {
        this.setActiveProduct(product);
        resolve();
      }).then(() => {
        this.closeProducts();
        window.location.reload();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

.products-mobile {
  display: block;
  background-color: $secondary;
  border-top: 1px solid $mobile-border-color;

  &__name {
    font: 600 17px/22px $font-bold;
    position: relative;
    display: flex;
    color: $primary;
    padding: 16px;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      height: 20px;
      width : 20px;
      background: url('~@/assets/icons/bottom-white-arrow.svg') no-repeat center center;
    }

    &_active {
      &:after {
        transform: translateY(-50%) rotate(180deg);
      }
    }

    &_single {
      &:after {
        display: none;
      }
    }
  }

  &__list {
    margin: 0;
    padding: 0;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 18px 12px 24px;
    list-style: none;

    &_active {
      .products-mobile__item-selection {
        &::before {
          display: block;
        }
      }
    }
  }

  &__item-name {
    font: 600 15px/18px $font-bold;
    color: $primary;
  }

  &__item-selection {
    position: relative;
    height: 16px;
    width: 16px;
    border: 1px solid $primary;
    border-radius: 50%;

    &::before {
      content: '';
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: $primary;
    }
  }
}

@media screen and (min-width: $tablet-breakpoint) {
  .products-mobile {
    display: none;
  }
}
</style>
